import { Col, Row, Tooltip } from "antd";
import Text from "../Text";
import banner from "../../assets/BannerImage.png";
import coin from "../../assets/Coin Logo.png";
import timmer from "../../assets/Svgs/stopwatch.svg";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import glob from "../../assets/Svgs/vuesax.svg";
import discoard from "../../assets/Svgs/SocialDiscoard.svg";
import twitter from "../../assets/Svgs/SocialTwitter.svg";
import telegram from "../../assets/Svgs/SocialTelegram.svg";
import linkedin from "../../assets/Svgs/SocialLinkedin.svg";
import { IdoListType } from "../../utils/types";
import Countdown from "../CountDown";

interface SingleIdoDetailCardProp {
  ido: IdoListType | undefined;
  getIdo: any;
}

const SingleIdoDetailCard = (props: SingleIdoDetailCardProp) => {
  const { ido, getIdo } = props;

  return (
    <Row
      style={{
        width: "100%",
        borderRadius: "24px",
        border: "1px solid rgba(255, 255, 255, 0.250)",
        overflow: "hidden",
      }}
    >
      <Row
        style={{
          height: "320px",
          width: "100%",
          backgroundImage: `url(${ido?.project_image})`,
          backgroundPosition: "center",
          backgroundSize: "cover",
          borderTopRightRadius: "24px",
          borderTopLeftRadius: "24px",
        }}
      ></Row>
      <Row
        style={{ width: "100%", padding: "24px", backgroundColor: "#120F2D" }}
      >
        <Row style={{ gap: "16px" }}>
          <img
            src={ido?.token.image ? ido.token.image : coin}
            style={{
              height: "60px",
              width: "60px",
              borderRadius: "50%",
              objectFit: "cover",
            }}
          />
          <Col style={{ display: "flex", flexDirection: "column" }}>
            <Text size="sm" style={{ fontSize: "24px", fontWeight: "700" }}>
              {ido?.token_name}
            </Text>
            <Text
              size="md"
              style={{ fontWeight: "600", color: "rgba(255, 255, 255, 0.50)" }}
            >
              {`${ido?.token?.name} (${ido?.token?.symbol})`}
            </Text>
          </Col>
        </Row>
        <Row style={{ width: "100%", marginTop: "24px" }}>
          <Text
            size="sm"
            style={{
              color: "rgba(255, 255, 255, 0.50)",
              fontWeight: "500",
              lineHeight: "22px",
              width: "100%",
            }}
          >
            {ido?.token_intro}
          </Text>
        </Row>
        <Row style={{ gap: "5px", marginTop: "24px", alignItems: "center" }}>
          <img src={timmer} />
          <Text
            size="xs"
            style={{ color: "rgba(255, 255, 255, 0.50)", fontWeight: "500" }}
          >
            Stage:
          </Text>
        </Row>
        <Row style={{ width: "100%", gap: "8px", marginTop: "8px" }}>
          <StaggerRow
            name="Interest"
            refreshFunction={getIdo}
            time={
              ido?.stage === "pending"
                ? ido.interest_start
                : ido?.stage === "interest"
                ? ido?.interest_end
                : "Closed"
            }
            active={ido?.stage === "interest" ? true : false}
          />
          <StaggerRow
            name="Allocations"
            refreshFunction={getIdo}
            time={
              ido?.stage === "interest" || ido?.stage === "pending"
                ? ido.allocation_start
                : ido?.stage === "allocation"
                ? ido.allocation_end
                : "Closed"
            }
            active={ido?.stage === "allocation" ? true : false}
          />
          <StaggerRow
            name="FCFS"
            refreshFunction={getIdo}
            time={
              ido?.stage === "interest" ||
              ido?.stage === "allocation" ||
              ido?.stage === "pending"
                ? ido.fcfs_start
                : ido?.stage === "fcfs"
                ? ido.fcfs_end
                : "Closed"
            }
            active={ido?.stage === "fcfs" ? true : false}
          />
          <StaggerRow
            name="Closed"
            refreshFunction={getIdo}
            time={
              ido?.stage === "interest" ||
              ido?.stage === "allocation" ||
              ido?.stage === "fcfs" ||
              ido?.stage === "pending"
                ? ido.fcfs_end
                : ido?.stage === "ended"
                ? "Closed"
                : "Closed"
            }
            active={ido?.stage === "ended" ? true : false}
          />
        </Row>
        <Row
          style={{
            marginTop: "24px",
            paddingTop: "16px",
            justifyContent: ido?.website ? "space-between" : "flex-end",
            alignItems: "center",
            borderTop: "1px solid rgba(255, 255, 255, 0.250)",
            width: "100%",
          }}
        >
          {ido?.website && (
            <Col
              style={{
                display: "flex",
                gap: "5px",
                alignItems: "center",
                cursor: "pointer",
              }}
              onClick={() => window.open(ido?.website, "_blank")}
            >
              <img src={glob} />
              <Text size="sm" style={{ fontWeight: "500" }}>
                Visit Site
              </Text>
            </Col>
          )}
          <Col style={{ display: "flex", alignItems: "center", gap: "16px" }}>
            {ido?.discord_url && (
              <img
                src={discoard}
                style={{ cursor: "pointer" }}
                onClick={() => window.open(ido?.discord_url, "_blank")}
              />
            )}
            {ido?.x_url && (
              <img
                src={twitter}
                style={{ cursor: "pointer" }}
                onClick={() => window.open(ido?.x_url, "_blank")}
              />
            )}
            {ido?.telegram_url && (
              <img
                src={telegram}
                style={{ cursor: "pointer" }}
                onClick={() => window.open(ido?.telegram_url, "_blank")}
              />
            )}
            {ido?.linked_in_url && (
              <img
                src={linkedin}
                style={{ cursor: "pointer" }}
                onClick={() => window.open(ido?.linked_in_url, "_blank")}
              />
            )}
          </Col>
        </Row>
      </Row>
    </Row>
  );
};

export default SingleIdoDetailCard;

interface StaggerRowProp {
  name: string;
  active: boolean;
  time: string;
  refreshFunction?: any;
}

const StaggerRow = (props: StaggerRowProp) => {
  const { name, active, time, refreshFunction } = props;
  return (
    <Row
      style={{
        width: "100%",
        padding: "8px 8px 8px 12px",
        justifyContent: "space-between",
        alignItems: "center",
        borderRadius: "18px",
        backgroundColor: "rgba(0, 0, 0, 0.10)",
        border: active ? "1px solid #33FFFF" : "1px solid transparent",
      }}
    >
      <Text
        size="md"
        style={{
          color: active ? "#33FFFF" : "rgba(255, 255, 255, 0.50)",
          fontWeight: "500",
        }}
      >
        {name}
      </Text>
      <Col style={{ display: "flex", gap: "8px" }}>
        <Text
          size="md"
          style={{
            color: active ? "#33FFFF" : "rgba(255, 255, 255, 0.50)",
            fontWeight: "500",
            alignSelf: "center",
          }}
        >
          {time !== "Closed" && active ? "Ends in" : ""}
        </Text>

        <TimeCard
          Time={time}
          active={active}
          refreshFunction={refreshFunction}
          stageName={name}
        />
      </Col>
    </Row>
  );
};

interface TimeCardProps {
  Time: string;
  active: boolean;
  refreshFunction?: any;
  stageName?: string;
}
const TimeCard = (props: TimeCardProps) => {
  const { Time, active, refreshFunction, stageName } = props;
  return (
    <Col
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        padding: "10px",
        borderRadius: "12px",
        backgroundColor: "rgba(255, 255, 255, 0.10)",
        backdropFilter: "blur(5px)",
        gap: "5px",
      }}
    >
      <Text
        size="md"
        style={{ fontWeight: "600", color: "rgba(255, 255, 255, 0.50)" }}
      >
        {Time === "Closed" ? "Closed" : <Countdown endDateString={Time} />}
      </Text>
      <Tooltip title={stageName ? tooltipMessage(stageName) : ""}>
        <ExclamationCircleOutlined
          style={{
            width: "14px",
            height: "14px",
            color: active ? "#33FFFF" : "#85858C",
            marginBottom: "2px",
            cursor: "pointer",
          }}
        />
      </Tooltip>
    </Col>
  );
};

const tooltipMessage = (stageName: string): string => {
  if (stageName === "Interest") {
    return "This round allows our strategic partner “Acquire.Fi” to reserve an allocation in our offer.";
  } else if (stageName === "Allocations") {
    return "This round allows our strategic partner “Acquire.Fi” to participate up to their allocated amount.";
  } else if (stageName === "FCFS") {
    return "This round is open to all onboarded Acquire New Ventures clients.";
  } else if (stageName === "Closed") {
    return "The offer will be closed.";
  } else {
    return "Invalid stage name.";
  }
};
