import { Col, InputNumber, Row, Slider, Spin, message } from "antd";
import Text from "../Text";
import {
  ClockCircleOutlined,
  ExclamationCircleOutlined,
} from "@ant-design/icons";
import clock from "../../assets/Svgs/Clock.svg";
import { Button } from "antd";
import solana from "../../assets/Svgs/Solana.svg";
import coin from "../../assets/Coin Logo.png";
import { useEffect, useState } from "react";
import { ExchangeToken, IdoListType } from "../../utils/types";
import verif from "../../assets/verifygold.png";
import Countdown from "../CountDown";
import { useAccount } from "wagmi";
import {
  buyAllocation,
  getIdoStage,
  getUserParticipating,
  optInUser,
} from "../../api/ApiCalls";
import Web3 from "web3";
import { erc20Abi } from "viem";
import BigNumber from "bignumber.js";
import { useSelector } from "react-redux";
import { userState } from "../../redux/reducers";
import { determineStages } from "../../Services/index";
import FiXCircle from "../../assets/FiXCircle.png";
import FiCheckCircle from "../../assets/FiCheckCircle.png";
import { CloseOutlined } from "@ant-design/icons";
import { Select } from "antd";

interface ParticipateCardProps {
  ido: IdoListType;
  getIdo: any;
  setIsModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const ParticipateCard = (props: ParticipateCardProps) => {
  const { ido, getIdo, setIsModalOpen } = props;
  const [inputValue, setInputValue] = useState<number>(0);
  const [loading, setLoading] = useState(true);
  const { address, connector } = useAccount();
  const [particapated, setParticipated] = useState<boolean>(false);
  const onChange = (newValue: any) => {
    setInputValue(newValue);
  };
  const [acqBalance, setAcqBalance] = useState<any>(0);
  const [SliderAmount, setSliderAmount] = useState<any>(0);
  const { user } = useSelector(userState);
  const [stageNext, setStageNext] = useState<string | null>(null);
  const [stageOver, setStageOver] = useState<string | null>(null);
  const [isSuccess, setIsSuccess] = useState<boolean>(false);
  const [buyMessageOpen, setBuyMessageOpen] = useState<boolean>(false);
  const [transactionLoading, setTransactionLoading] = useState<boolean>(false);
  const [selectedExchangeToken, setSelectedExchangeToken] =
    useState<ExchangeToken | null>(null);

  useEffect(() => {
    setSelectedExchangeToken(ido.exchange_tokens[0]);
  }, [ido]);

  const handleNextOverStage = async () => {
    await determineStages(
      ido.interest_start,
      ido.allocation_start,
      ido.fcfs_start,
      setStageNext,
      setStageOver
    );
  };

  const handleBuyButtonClick = async () => {
    setLoading(true);
    setTransactionLoading(true);
    try {
      // check total user spend before purchasing
      const amount_left_to_spend = ido?.total_user_spend
        ? 25000 - ido?.total_user_spend
        : 0;

      if (Number(ido.total_user_spend) + inputValue > 25000) {
        message.error(
          `You have exceeded the maximum amount of 25,000 ${
            selectedExchangeToken?.symbol
          } per offer, ${
            amount_left_to_spend > 0
              ? "you can only spend " +
                amount_left_to_spend +
                " " +
                selectedExchangeToken?.symbol
              : ""
          }`
        );
        setLoading(false);
        setTransactionLoading(false);
        setIsSuccess(false);
        setTransactionLoading(false);
        setBuyMessageOpen(false);
        return;
      }

      // Get provider from connector or fallback to rpc_url
      const provider: any = await (connector?.getProvider() ||
        ido.network.rpc_url);

      // Initialize Web3 with the provider
      const web3 = new Web3(provider);

      console.log(selectedExchangeToken);
      // Instantiate the ERC20 contract
      const contract = new web3.eth.Contract(
        erc20Abi,
        selectedExchangeToken?.address
      );

      const userBalance: any = await contract.methods.balanceOf(address).call();

      if (inputValue < 100) {
        message.error(
          `Minimum of 100 ${selectedExchangeToken?.symbol} to complete transaction`
        );
        setIsSuccess(false);
        setTransactionLoading(false);
        setLoading(false);
        return;
      }

      const amountToSend: any = new BigNumber(
        web3.utils.toWei(
          Number(inputValue),
          selectedExchangeToken?.decimals === 18 ? "ether" : "tether"
        )
      );

      if (new BigNumber(userBalance).lt(amountToSend)) {
        console.log("Insufficient balance.");
        message.error(
          `Insufficient balance, user balance ${
            Number(userBalance) /
            10 **
              (selectedExchangeToken?.decimals
                ? selectedExchangeToken?.decimals
                : 18)
          } trying to send ${amountToSend.toString()}`
        );
        setIsSuccess(false);
        setTransactionLoading(false);
        setBuyMessageOpen(true);
        return;
      }

      // Initiate the token transfer
      const tx = await contract.methods
        .transfer(
          ido.wallet_address,
          web3.utils.toWei(
            Number(inputValue),
            selectedExchangeToken?.decimals === 18 ? "ether" : "tether"
          )
        )
        .send({ from: address });

      const payload = {
        transactionHash: tx.transactionHash,
      };
      const response = await buyAllocation(String(ido.id) || "", payload);
      getIdo();

      setIsSuccess(true);
      setBuyMessageOpen(true);
      setTransactionLoading(false);

      // getIdo();
    } catch (error) {
      message.error(`${error}`);
      console.log("error", error);
      setIsSuccess(false);
      setTransactionLoading(false);
      setBuyMessageOpen(true);
    }

    setLoading(false);
  };

  const getParticipating = async () => {
    try {
      const res = await getUserParticipating(ido?.id || 0);
      setParticipated(res.opted_in);
      setLoading(false);
    } catch (err) {
      console.log("Error While checking opted in ", err);
    }
  };

  const hangleOptIn = async () => {
    setLoading(true);
    const response = await optInUser(ido?.id || 0);

    if (response.data.ido) {
      setParticipated(true);
    }

    setLoading(false);
  };

  useEffect(() => {
    const fetchIDOStage = async () => {
      try {
        const response = await getIdoStage(ido.id);
        const newStage = response.stage;

        if (newStage !== ido.stage) {
          window.location.reload();
        }
      } catch (error) {
        console.error("Error fetching offer stage:", error);
      }
    };

    fetchIDOStage();
    handleNextOverStage();

    const interval = setInterval(fetchIDOStage, 5000);

    return () => clearInterval(interval);
  }, [ido.id]);

  const getuserAcqBalance = async () => {
    // @ts-ignore
    const web3 = new Web3(ido.network.rpc_url);

    const contract = new web3.eth.Contract(
      erc20Abi,
      "0xE5D3377465D5B1A0096f251Cd7F659dE041F374c"
    );

    const decimals: any = await contract.methods.decimals().call();
    const balance: any = await contract.methods.balanceOf(address).call();

    const formatedBalance: any = new BigNumber(balance).div(
      new BigNumber(10).pow(Number(decimals))
    );

    setAcqBalance(Number(formatedBalance.toString()).toFixed(4));
  };
  useEffect(() => {
    // check if user is already participating

    if (address && connector) {
      getParticipating();
      getuserAcqBalance();
    } else {
      setLoading(false);
    }
  }, [address, connector]);

  const handleSliderChange = (value: any) => {
    setSliderAmount(value);
    setInputValue(Number(value) * Number(ido?.price || 0));
  };

  const handleInputChange = (value: any) => {
    setInputValue(value);
    setSliderAmount(Number(value) / Number(ido?.price || 0));
  };

  const handleInput1Change = (value: any) => {
    setSliderAmount(value);
    setInputValue(Number(value) * Number(ido?.price || 0));
  };

  const handleExchangeTokenOnchange = (value: string) => {
    console.log(value, "----value");
    const selectedToken = ido.exchange_tokens.find(
      (token) => token.id === value
    );

    if (selectedToken) setSelectedExchangeToken(selectedToken);
  };

  return (
    <>
      {ido?.stage === "interest" || ido?.stage === "pending" ? (
        <Row
          style={{
            width: "100%",
            padding: "24px",
            gap: "24px",
            justifyContent: "center",
            borderRadius: "24px",
            border: "1px solid rgba(255, 255, 255, 0.250)",
            backgroundColor: " #120F2D",
          }}
        >
          <Text
            size="sm"
            style={{ fontWeight: "500", width: "100%", textAlign: "center" }}
          >
            Use $ACQ to secure an allocation:
          </Text>

          <Row
            style={{
              gap: "10px",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            {ido.stage !== "pending" && (
              <Text size="md" style={{ fontWeight: "600" }}>
                {`${
                  ido.stage === `interest`
                    ? `The Allocation will start in:`
                    : `${
                        ido.stage === `allocation`
                          ? `The FCFS will start in`
                          : ``
                      }`
                }`}
              </Text>
            )}

            {ido.stage === "pending" && (
              <Text size="md" style={{ fontWeight: "600" }}>
                {`${
                  stageOver !== null ? `The ${stageOver} stage is over. ` : ``
                }`}
                {`${
                  stageNext !== null ? `The ${stageNext} will start in:` : ``
                }`}
              </Text>
            )}

            <Row style={{ gap: "10px", display: "flex", alignItems: "center" }}>
              <img src={clock} />
              <Text size="md" style={{ fontWeight: "600" }}>
                {ido.stage !== "pending" && (
                  <Countdown
                    endDateString={
                      ido.stage === "interest"
                        ? ido.allocation_start
                        : ido.stage === "allocation"
                        ? ido.fcfs_start
                        : ""
                    }
                  />
                )}

                {ido.stage === "pending" && (
                  <>
                    {stageNext !== null && (
                      <Countdown
                        endDateString={
                          stageNext === "Interest"
                            ? ido.interest_start
                            : stageNext === "Allocation"
                            ? ido.allocation_start
                            : stageNext === "FCFS"
                            ? ido.fcfs_start
                            : ""
                        }
                      />
                    )}
                  </>
                )}
              </Text>
            </Row>
          </Row>

          <>
            {!particapated ? (
              <>
                {ido.stage === "interest" && (
                  <Button
                    type="primary"
                    style={{ width: "100%" }}
                    onClick={() => {
                      if (
                        user?.kyc_complete === true &&
                        user.questions_verified === true
                      ) {
                        hangleOptIn();
                      } else {
                        setIsModalOpen(true);
                      }
                    }}
                    disabled={loading}
                  >
                    Participate
                  </Button>
                )}
              </>
            ) : (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  width: "100%",
                  padding: "10px 12px",
                  borderRadius: "10px",
                  border: "1px solid #33FFFF",
                  gap: "6px",
                }}
              >
                <img src={verif} />
                <Text size="md" style={{ color: "#33FFFF", fontWeight: "500" }}>
                  You are participating!
                </Text>
              </div>
            )}
          </>
        </Row>
      ) : (
        <Row
          style={{
            flexDirection: "column",
            gap: "16px",
            padding: "16px",
            borderRadius: "24px",
            border: "1px solid rgba(255, 255, 255, 0.250)",
            backgroundColor: "#120F2D",
            width: "100%",
            opacity: ido?.stage === "ended" ? "0.5" : "none",
            backdropFilter: ido?.stage === "ended" ? "blur(2.5px)" : "none",
            position: "relative",
          }}
        >
          {/*///////////////////////////////////// FirstRow ////////////////////////////////////////// */}
          <Row
            style={{
              width: "100%",
              justifyContent: "space-between",
              alignItems: "flex-start",
            }}
          >
            <Col
              style={{
                display: "flex",
                gap: "16px",
                alignItems: "center",
                position: "relative",
              }}
            >
              <img
                src={ido?.token?.image}
                style={{
                  height: "42px",
                  width: "42px",
                  borderRadius: "50%",
                  objectFit: "cover",
                }}
              />
              <img
                src={selectedExchangeToken?.image}
                style={{
                  width: "18px",
                  height: "18px",
                  borderRadius: "50%",
                  position: "absolute",
                  top: -3,
                  left: 25,
                }}
              />
              <Row
                style={{
                  alignSelf: "stretch",
                  flexDirection: "column",
                  justifyContent: "space-between",
                }}
              >
                <Text size="md" style={{ fontWeight: "700" }}>
                  {ido?.token?.name}
                </Text>
                <Text size="xs" style={{ color: "rgba(255, 255, 255, 0.50)" }}>
                  {ido?.token?.symbol}
                </Text>
              </Row>
            </Col>
            <Col style={{ display: "flex", alignItems: "center", gap: "5px" }}>
              <Text size="md" style={{ color: "rgba(255, 255, 255, 0.50)" }}>
                Open
              </Text>
              <ClockCircleOutlined
                style={{
                  height: "18px",
                  width: "18px",
                  color: true ? "#33FFFF" : "rgba(255, 255, 255, 0.50)",
                }}
              />
            </Col>
          </Row>

          {/*//////////////////////////////////// Secound Row //////////////////////////////////////// */}

          <Row
            style={{
              flexDirection: "column",
              padding: "16px",
              borderRadius: "16px",
              backgroundColor: "rgba(0, 0, 0, 0.20)",
            }}
          >
            {/*///////////////////////////////////// Slider Row ////////////////////////////////////////*/}
            <Row
              style={{
                padding: "10px",
                borderRadius: "8px",
                backgroundColor: "#120F2D",
                gap: "16px",
              }}
            >
              <Row
                style={{
                  justifyContent: "space-between",
                  alignItems: "center",
                  width: "100%",
                }}
              >
                <Text size="sm" style={{ color: "rgba(255, 255, 255, 0.50" }}>
                  AMOUNT AVAILABLE
                </Text>
                <Text size="sm" style={{ color: "rgba(255, 255, 255, 0.50" }}>
                  100% <span style={{ marginLeft: "10px" }}>MAX</span>
                </Text>
              </Row>
              <Row style={{ width: "100%" }}>
                <Slider
                  style={{ width: "100%" }}
                  min={0}
                  disabled={ido?.stage === "ended"}
                  max={
                    ido?.stage === "allocation" || ido?.stage === "fcfs"
                      ? ido?.user_max_amount || 0
                      : Number(ido?.amount) || 0
                  }
                  onChange={handleSliderChange}
                  value={SliderAmount}
                />
              </Row>
            </Row>

            <Row
              style={{
                justifyContent: "space-between",
                alignItems: "center",
                marginTop: "24px",
              }}
            >
              <Col
                style={{ display: "flex", alignItems: "center", gap: "5px" }}
              >
                <Col
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    padding: "7px 8px",
                    borderRadius: "8px",
                    backgroundColor: "#28204E",
                    backdropFilter: "blur(5px)",
                  }}
                >
                  <Text size="sm" style={{ color: "#33FFFF" }}>
                    BUYING
                  </Text>
                </Col>
                <ExclamationCircleOutlined style={{ color: "#353535" }} />
              </Col>
              <img
                src={ido?.token?.image}
                style={{ width: "24px", height: "24px", borderRadius: "50%" }}
              />
            </Row>

            <Row
              style={{
                marginTop: "14px",
                paddingBottom: "18px",
                borderBottom: "1px solid rgba(255, 255, 255, 0.250)",
              }}
            >
              <InputNumber
                min={0}
                max={
                  ido?.stage === "allocation"
                    ? ido?.user_max_amount || 0
                    : Number(ido?.amount) || 0
                }
                value={SliderAmount}
                onChange={handleInput1Change}
                disabled={ido?.stage === "ended"}
              />
              <Text size="sm" style={{ color: "rgba(255, 255, 255, 0.25)" }}>
                {ido?.token?.symbol}
              </Text>
            </Row>

            <Row
              style={{
                justifyContent: "space-between",
                alignItems: "center",
                marginTop: "18px",
              }}
            >
              <Col
                style={{ display: "flex", alignItems: "center", gap: "5px" }}
              >
                <Col
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    padding: "7px 8px",
                    borderRadius: "8px",
                    backgroundColor: "#28204E",
                    backdropFilter: "blur(5px)",
                  }}
                >
                  <Text size="sm" style={{ color: "#33FFFF" }}>
                    FOR
                  </Text>
                </Col>
                <ExclamationCircleOutlined style={{ color: "#353535" }} />
              </Col>

              <Col>
                <Select
                  defaultValue={ido.exchange_tokens[0].id}
                  onChange={handleExchangeTokenOnchange}
                >
                  {ido.exchange_tokens.map((token) => (
                    <Select.Option key={token.id} value={token.id}>
                      <Row style={{ gap: "5px", alignItems: "center" }}>
                        <img
                          src={token.image}
                          style={{
                            height: "18px",
                            width: "18px",
                            borderRadius: "50%",
                          }}
                        />
                        <Text
                          size="sm"
                          style={{ color: "rgba(255, 255, 255, 0.25)" }}
                        >
                          {token.symbol}
                        </Text>
                      </Row>
                    </Select.Option>
                  ))}
                </Select>
              </Col>
            </Row>

            <Row style={{ marginTop: "14px" }}>
              <InputNumber
                min={0}
                value={inputValue}
                disabled={ido?.stage === "ended"}
              />
              <Text size="sm" style={{ color: "rgba(255, 255, 255, 0.25)" }}>
                {selectedExchangeToken?.symbol}
              </Text>
            </Row>

            {(ido.stage === "fcfs" || ido.stage === "allocation") && (
              <Row style={{ width: "100%" }}>
                <Text size="sm" style={{ color: "#C43D3D" }}>
                  {inputValue < 100
                    ? "Minimum amount is 100 USD"
                    : inputValue > 25000
                    ? "Maximum amount is 25,000 USD"
                    : ""}
                </Text>
              </Row>
            )}

            {ido?.stage !== "ended" && (
              <Button
                type="primary"
                style={{
                  width: "100%",
                  marginTop: "24px",
                }}
                onClick={() => {
                  if (!address) {
                    message.error("First Connect Your Wallet");
                  } else {
                    if (ido?.stage === "ended") {
                      message.error("This offer has ended");
                    } else {
                      if (
                        user.kyc_complete === true &&
                        user.questions_verified === true
                      ) {
                        handleBuyButtonClick();
                      } else {
                        setIsModalOpen(true);
                      }
                    }
                  }
                }}
                disabled={
                  loading ||
                  ido?.stage === "ended" ||
                  buyMessageOpen ||
                  inputValue < 100 ||
                  inputValue > 25000
                }
                loading={loading}
              >
                {loading ? "Creating Allocation" : "Buy"}
              </Button>
            )}

            {loading && (
              <Text
                size="xs"
                style={{ textAlign: "center", marginTop: "10px" }}
              >
                Processing, this may take some time...
              </Text>
            )}

            <Row style={{ marginTop: "14px" }}>
              {ido?.stage === "allocation" && (
                <>
                  {ido.user_purchased_amount &&
                  ido?.user_purchased_amount > 0 ? (
                    <Text
                      size="xs"
                      style={{ color: "rgba(255, 255, 255, 0.50)" }}
                    >
                      {ido.user_purchased_amount <= 0 ? (
                        `You haven't purchased ${ido.token.symbol} yet.`
                      ) : (
                        <>
                          You have already purchased{" "}
                          <span style={{ color: "#33FFFF" }}>
                            {ido.user_purchased_amount} {ido.token.symbol}
                          </span>
                        </>
                      )}
                    </Text>
                  ) : (
                    <Text
                      size="xs"
                      style={{ color: "rgba(255, 255, 255, 0.50" }}
                    >
                      *Because you have{" "}
                      <span style={{ color: "#33FFFF" }}>{acqBalance} ACQ</span>
                      , you are allowed to participate this much.
                    </Text>
                  )}
                </>
              )}
              {ido?.stage === "fcfs" && (
                <>
                  {ido.user_purchased_amount &&
                  ido.user_purchased_amount > 0 ? (
                    <Text
                      size="xs"
                      style={{ color: "rgba(255, 255, 255, 0.50" }}
                    >
                      *You have already purchased{" "}
                      <span style={{ color: "#33FFFF" }}>
                        {ido.user_purchased_amount} {ido.token.symbol}
                      </span>
                    </Text>
                  ) : (
                    <Text size="xs">
                      You haven't purchased{" "}
                      <span style={{ color: "#33FFFF" }}>
                        {ido.token.symbol}
                      </span>{" "}
                      yet
                    </Text>
                  )}
                  <Text
                    size="xs"
                    style={{
                      color: "rgba(255, 255, 255, 0.50",
                      marginTop: "7px",
                      width: "100%",
                    }}
                  >
                    *Anyone can participate in this period, first come, first
                    served.
                  </Text>
                </>
              )}
            </Row>
          </Row>

          {/*//////////////////////////////////// absolute text //////////////////////////////////////// */}
          <Row
            style={{
              justifyContent: "center",
              position: "absolute",
              top: "50%",
              width: "100%",
              opacity: "1",
            }}
          >
            {ido?.stage === "ended" && (
              <>
                <Text
                  size="sm"
                  style={{
                    fontWeight: "700",
                    fontSize: "24px",
                    color: "white",
                    opacity: "33",
                  }}
                >
                  The offer has been closed
                </Text>
              </>
            )}
          </Row>

          {ido?.stage !== "ended" &&
            ido?.stage !== "fcfs" &&
            particapated === false && (
              <Row
                style={{
                  justifyContent: "center",
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  transform: "translate(-50%, -50%)",
                  width: "100%",
                  opacity: "1",
                  border: "1px solid rgba(255, 255, 255, 0.250)",
                  borderRadius: "24px",
                  background: "#120F2D",
                  padding: "24px",
                  maxWidth: "360px",
                }}
              >
                <Text
                  size="sm"
                  style={{
                    fontWeight: "500",
                    fontSize: "20px",
                    color: "white",
                    opacity: "33",
                    marginBottom: "12px",
                    textAlign: "center",
                  }}
                >
                  You cannot buy an allocation
                </Text>
                <Text
                  size="sm"
                  style={{
                    fontWeight: "500",
                    fontSize: "14px",
                    color: "rgba(255, 255, 255, 0.50)",
                    opacity: "33",
                    marginBottom: "12px",
                    textAlign: "center",
                  }}
                >
                  You cannot buy an allocation because you did not participate
                  during the interest stage.
                </Text>
              </Row>
            )}

          {/* loading */}
          {transactionLoading && (
            <Row
              style={{
                justifyContent: "center",
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                width: "100%",
                opacity: "1",
                border: "1px solid rgba(255, 255, 255, 0.250)",
                borderRadius: "24px",
                background: "#120F2D",
                padding: "24px",
                maxWidth: "360px",
              }}
            >
              <Spin />

              <Text
                size="xs"
                style={{
                  fontWeight: "500",
                  fontSize: "20px",
                  color: "white",
                  opacity: "33",
                  marginBottom: "12px",
                  marginTop: "12px",
                  textAlign: "center",
                }}
              >
                We're currently working on your transaction. You've bought for{" "}
                {SliderAmount} {ido.token.symbol}. Check your metamask for the
                current status.
              </Text>
            </Row>
          )}

          {/* loading */}

          {buyMessageOpen && (
            <Row
              style={{
                justifyContent: "center",
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                width: "100%",
                opacity: "1",
                border: "1px solid rgba(255, 255, 255, 0.250)",
                borderRadius: "24px",
                background: "#120F2D",
                padding: "24px",
                maxWidth: "360px",
              }}
            >
              <CloseOutlined
                style={{
                  color: "white",
                  position: "absolute",
                  right: "20px",
                  cursor: "pointer",
                }}
                onClick={() => {
                  setBuyMessageOpen(false);
                  if (isSuccess) {
                    window.location.reload();
                  }
                }}
              />
              <img src={isSuccess ? FiCheckCircle : FiXCircle} />
              <Text
                size="sm"
                style={{
                  fontWeight: "500",
                  fontSize: "20px",
                  color: "white",
                  opacity: "33",
                  marginBottom: "12px",
                  marginTop: "12px",
                  textAlign: "center",
                }}
              >
                {isSuccess
                  ? "Allocation bought successfully!"
                  : "Oops, something went wrong"}
              </Text>
              <Text
                size="sm"
                style={{
                  fontWeight: "500",
                  fontSize: "14px",
                  color: "rgba(255, 255, 255, 0.50)",
                  opacity: "33",
                  textAlign: "center",
                }}
              >
                {isSuccess
                  ? "Your transaction has been completed successfully. You can check your tickets in your account."
                  : "An error occured during making the transaction. Check your balance and try again later."}
              </Text>
            </Row>
          )}
        </Row>
      )}
    </>
  );
};

export default ParticipateCard;
