import { Col, Row } from "antd";
import React from "react";
import Text from "../Text";
import eth from "../../assets/Svgs/Ethereum.svg";
import useBreakpoint from "antd/es/grid/hooks/useBreakpoint";
import { IdoListType } from "../../utils/types";
import {
  formatNumberWithTwoDecimalPlaces,
  ValuesFormatDecimalAndCommas,
} from "../../Services";

interface ProjectOverviewCardInterface {
  ido: IdoListType | undefined;
}

const ProjectOverviewCard = (props: ProjectOverviewCardInterface) => {
  const { ido } = props;

  const { xs, sm, md, lg, xl, xxl } = useBreakpoint();
  return (
    <Row
      style={{
        width: "100%",
        padding: "24px",
        borderRadius: "24px",
        border: "1px solid rgba(255, 255, 255, 0.250)",
        backgroundColor: "#120F2D",
      }}
    >
      {ido?.project_overview && (
        <Row style={{ gap: "10px" }}>
          <Text size="md" style={{ fontWeight: "600", width: "100%" }}>
            PROJECT OVERVIEW
          </Text>
          <Text
            size="sm"
            style={{
              fontWeight: "500",
              lineHeight: "22px",
              color: "rgba(255, 255, 255, 0.50)",
            }}
          >
            {ido?.project_overview}
          </Text>
        </Row>
      )}
      <Row style={{ marginTop: "36px", gap: "10px", width: "100%" }}>
        <Text size="md" style={{ fontWeight: "500" }}>
          Offer Details
        </Text>
        <Row
          style={{
            padding: "16px",
            backgroundColor: "rgba(0, 0, 0, 0.20)",
            width: "100%",
            borderRadius: "14px",
          }}
        >
          <ValueRow
            name="Launch Price"
            value={`${ValuesFormatDecimalAndCommas(ido?.price)} ${
              ido?.exchange_tokens[0].symbol
            }`}
          />
          <ValueRow
            name="Total Amount"
            value={`${ValuesFormatDecimalAndCommas(
              (Number(ido?.amount) * Number(ido?.price)).toFixed(3)
            )} ${ido?.exchange_tokens[0].symbol}`}
          />
          <ValueRow
            name="Total Issued"
            value={`${ValuesFormatDecimalAndCommas(ido?.amount)} ${
              ido?.token.symbol
            }`}
          />
          <ValueRow
            name="Acquire Fee"
            value={`${ValuesFormatDecimalAndCommas(ido?.acquire_fee)} %
            `}
          />
        </Row>
      </Row>
      <Row style={{ marginTop: "36px", gap: "10px", width: "100%" }}>
        <Text size="md" style={{ fontWeight: "500" }}>
          KEY METRICS
        </Text>
        <Row
          style={{
            padding: "16px",
            backgroundColor: "rgba(0, 0, 0, 0.20)",
            width: "100%",
            borderRadius: "14px",
          }}
        >
          <Col
            span={md ? 12 : 24}
            style={{
              display: "flex",
              flexDirection: "column",
              borderRight: md ? "1px solid rgba(255, 255, 255, 0.250)" : "none",
              gap: "8px",
              paddingRight: md ? "10px" : "0px",
              paddingBottom: md ? "0px" : "10px",
              marginBottom: md ? "0px" : "10px",
              borderBottom: md ? "none" : "1px solid rgba(255, 255, 255, 0.250)",
            }}
          >
            <ValueRow
              name="Blockchain Network"
              value={ido?.token?.network?.name || ""}
              icon={ido?.network.network_icon}
            />
            {ido?.total_supply && (
              <ValueRow
                name="Total Supply"
                value={`${ValuesFormatDecimalAndCommas(ido?.total_supply)}`}
              />
            )}
            {ido?.valuation && (
              <ValueRow
                name="Project Valuation"
                value={`$${ValuesFormatDecimalAndCommas(ido?.valuation)}`}
              />
            )}
          </Col>
          <Col
            span={md ? 12 : 24}
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "8px",
              paddingLeft: md ? "10px" : "0px",
            }}
          >
            {ido?.initial_market_cap && (
              <ValueRow
                name="Initial Market Cap"
                value={`$${ValuesFormatDecimalAndCommas(
                  ido?.initial_market_cap
                )}`}
              />
            )}
            {ido?.hard_cap && (
              <ValueRow
                name="Allocation Max"
                value={`$${ValuesFormatDecimalAndCommas(ido?.hard_cap)}`}
              />
            )}
            {ido?.platform_raise && (
              <ValueRow
                name="Platform Raise"
                value={`$${ValuesFormatDecimalAndCommas(ido?.platform_raise)}`}
              />
            )}
          </Col>
        </Row>
      </Row>
      {ido?.revenue_model && (
        <Row style={{ marginTop: "36px" }}>
          <Text size="md" style={{ fontWeight: "600", width: "100%" }}>
            REVENUE MODEL
          </Text>
          <Row style={{ gap: "16px", width: "100%", marginTop: "16px" }}>
            <Text
              size="sm"
              style={{
                color: "rgba(255, 255, 255, 0.50)",
                fontWeight: "500",
                lineHeight: "22px",
              }}
            >
              {ido?.revenue_model}
            </Text>
          </Row>
        </Row>
      )}
    </Row>
  );
};

export default ProjectOverviewCard;

interface ValueRowProp {
  name: string;
  value: string;
  icon?: string;
}
export const ValueRow = (props: ValueRowProp) => {
  const { icon, name, value } = props;

  return (
    <Row
      style={{
        padding: "10px 0px",
        justifyContent: "space-between",
        alignItems: "center",
        flexWrap: "nowrap",
        width: "100%",
      }}
    >
      <Col style={{ display: "flex" }}>
        <Text
          size="sm"
          style={{ color: "rgba(255, 255, 255, 0.50)", fontWeight: "500" }}
        >
          {name}
        </Text>
      </Col>
      <Col style={{ display: "flex", alignItems: "center", gap: "8px" }}>
        <Text size="sm" style={{ fontWeight: "500", whiteSpace: "nowrap" }}>
          {value}
        </Text>
        {icon && (
          <img
            src={icon}
            style={{ height: "16px", width: "16px", borderRadius: "50%" }}
          />
        )}
      </Col>
    </Row>
  );
};
