import { Button, Col, ConfigProvider, Row } from "antd";
import hero from "../assets/Svgs/home_hero.svg";
import Text from "./Text";
import { ButtonProps } from "antd/es/button";
import { IconOfferButton } from "../components/Icons";
import useBreakpoint from "antd/es/grid/hooks/useBreakpoint";

type HeroProps = {
  title: string;
  title2: string;
  subtitle: string;
  icon: React.ReactNode;
  buttonText: string;
};

const Hero = (prop: HeroProps) => {
  const { buttonText, icon, subtitle, title, title2 } = prop;
  const { xs, sm, md, lg, xl, xxl } = useBreakpoint();

  // console.log(
  //   "xs:",
  //   xs,
  //   " sm:",
  //   sm,
  //   "md:",
  //   md,
  //   "lg:",
  //   lg,
  //   "xl:",
  //   xl,
  //   "xxl:",
  //   xxl
  // );
  return (
    <Row
      style={{
        width: "100%",
        // height: "273px",
        height: xs ? "250px" : "305px",
        backgroundImage: `url(${hero})`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        backgroundPosition: "center",
        borderRadius: "24px",
        justifyContent: "start",
        alignItems: "center",
        border: "1px solid rgba(91, 91, 91, 0.10)",
        position: "relative",
        padding: xs ? "0px 20px" : "0px 0px",
      }}
    >
      <Row
        style={{
          flexDirection: "column",
          justifyContent: "left",
          gap: xs ? "10px" : "10px",
          marginTop: xs ? "50px" : "20px",
          paddingLeft: "36px",
        }}
      >
        <Text
          size="xxl"
          style={{
            fontSize: xs ? "22px" : md ? "45px" : "31px",
            textAlign: "center",
          }}
        >
          <span style={{ color: "#33FFFF" }}>{title}</span> {title2}
        </Text>
        <Text
          size="md"
          style={{
            color: "rgba(255, 255, 255, 0.50)",
            fontSize: sm ? "14px" : "16px",
          }}
        >
          {subtitle}
        </Text>
      </Row>

      <div style={{ position: "absolute", top: "-1px", left: "-1px" }}>
        <svg
          width="198"
          height="85"
          viewBox="0 0 198 85"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g clip-path="url(#clip0_276_3350)">
            <mask id="path-1-inside-1_276_3350" fill="white">
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M0 85C0 71.7452 10.7452 61 24 61H148C161.255 61 172 50.2548 172 37V24C172 10.7452 182.745 0 196 0H0V85ZM0 249V273H24C10.7452 273 0 262.255 0 249ZM1078 273H1102V249C1102 262.255 1091.25 273 1078 273ZM1102 24V0H1078C1091.25 0 1102 10.7452 1102 24Z"
              />
            </mask>
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M0 85C0 71.7452 10.7452 61 24 61H148C161.255 61 172 50.2548 172 37V24C172 10.7452 182.745 0 196 0H0V85ZM0 249V273H24C10.7452 273 0 262.255 0 249ZM1078 273H1102V249C1102 262.255 1091.25 273 1078 273ZM1102 24V0H1078C1091.25 0 1102 10.7452 1102 24Z"
              fill="#0C0A1F"
            />
            <path
              d="M0 0V-1H-1V0H0ZM0 273H-1V274H0V273ZM1102 273V274H1103V273H1102ZM1102 0H1103V-1H1102V0ZM24 60C10.1929 60 -1 71.1929 -1 85H1C1 72.2974 11.2975 62 24 62V60ZM148 60H24V62H148V60ZM171 37C171 49.7025 160.703 60 148 60V62C161.807 62 173 50.8071 173 37H171ZM171 24V37H173V24H171ZM196 -1C182.193 -1 171 10.1929 171 24H173C173 11.2975 183.297 1 196 1V-1ZM0 1H196V-1H0V1ZM1 85V0H-1V85H1ZM1 273V249H-1V273H1ZM24 272H0V274H24V272ZM-1 249C-1 262.807 10.1929 274 24 274V272C11.2975 272 1 261.703 1 249H-1ZM1102 272H1078V274H1102V272ZM1101 249V273H1103V249H1101ZM1078 274C1091.81 274 1103 262.807 1103 249H1101C1101 261.703 1090.7 272 1078 272V274ZM1101 0V24H1103V0H1101ZM1078 1H1102V-1H1078V1ZM1103 24C1103 10.1929 1091.81 -1 1078 -1V1C1090.7 1 1101 11.2975 1101 24H1103Z"
              fill="#5B5B5B"
              fill-opacity="0.1"
              mask="url(#path-1-inside-1_276_3350)"
            />
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M0 83C0 70.2975 10.2975 60 23 60H148C160.703 60 171 49.7025 171 37V22C171 11.6387 177.851 2.87754 187.271 0H1085.73C1095.15 2.87754 1102 11.6387 1102 22V0H1085.73C1083.6 -0.650195 1081.34 -1 1079 -1H194C191.659 -1 189.399 -0.650195 187.271 0H0V83ZM0 249V272H23C10.2975 272 0 261.703 0 249ZM1079 272H1102V249C1102 261.703 1091.7 272 1079 272Z"
              fill="#0C0A1F"
            />
          </g>
          <defs>
            <clipPath id="clip0_276_3350">
              <rect width="198" height="85" fill="white" />
            </clipPath>
          </defs>
        </svg>
      </div>

      <div style={{ position: "absolute", top: "0px", left: "0px", pointerEvents:'none' }}>
        <HeroButton icon={icon} size="large">
          {buttonText}
        </HeroButton>
      </div>

      {/* <Row
        style={{
          width: "40px",
          height: "70px",
          backgroundColor: xs ? "green" : md ? "red" : "yellow",
        }}
      ></Row> */}
    </Row>
  );
};

export default Hero;

interface Props extends ButtonProps {
  name?: string;
  color?: string;
  style?: React.CSSProperties;
}

const HeroButton = (props: Props) => {
  const { name, style, children, color, ...prop } = props;
  return (
    <ConfigProvider
      theme={{
        components: {
          Button: {
            paddingBlockLG: 12,
            controlHeightLG: 49,
            fontSize: 24,
            fontFamily: "Montserrat",
            colorText: "rgba(255, 255, 255, 0.50)",
            fontWeight: 500,
            borderRadiusLG: 14,
            ///////Default Button/////////
            defaultBg: "#120F2D",
            defaultBorderColor: "#120F2D",
            defaultColor: "rgba(255, 255, 255, 0.50)",
            defaultHoverBg: "#120F2D",
            defaultHoverColor: "rgba(255, 255, 255, 0.50)",
          },
        },
      }}
    >
      <Button {...prop}> {name ? name : children}</Button>
    </ConfigProvider>
  );
};
